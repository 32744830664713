import React, { useEffect, useState } from "react";
// import { Feature } from "flagged";

// Material components
import MDBox from "components/MDBox";
import { Card, Divider, Grid } from "@mui/material";

// Custom components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import CustomButton from "examples/NewDesign/CustomButton";
import BasicModal from "examples/modal/BasicModal/BasicModal";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import FDropdown from "components/Dropdown/FDropdown";
import DataTable from "examples/Tables/DataTable";
import FTextField from "components/Form/FTextField";
import pxToRem from "assets/theme/functions/pxToRem";

// Table Data
import BankOpeningData from "layouts/bankOpening/data/BankOpeningData";

// Redux
import { useDispatch, useSelector } from "react-redux";

import submitBankOpeningForm, { getAllBanksOpeningList } from "redux/Thunks/BankOpening";
import { openSnackbar } from "redux/Slice/Notification";
import { relaodBankOpeningList } from "redux/Slice/BankOpening";

// Utils
import Constants, {
  Icons,
  PageTitles,
  ButtonTitles,
  Colors,
  ModalContent,
  //   FeatureTags,
  defaultData,
} from "utils/Constants";
import { paramCreater } from "utils/methods/methods";
import Validator from "utils/Validations";

const initialBankOpeningState = {
  type: "new",
  openModal: false,
  list: [],
  body: {
    beneficiary_name: "",
    beneficiary_email: "",
    beneficiary_mobile_no: "",
    beneficiary_pan: "",
    beneficiary_adhar: "",
    bank_opening_category: "",
    bank_opening_name: "",
  },
  errors: {},
  loading: false,
};

function SetupReport() {
  const [bankOpening, setBankOpening] = useState(initialBankOpeningState);

  const [loading, setLoading] = useState(false);

  const [tablePagination, setTablePagination] = useState({
    page: 0,
    perPage: defaultData.DATE_ON_SINGLE_API_CALL,
  });
  const [next, setNext] = useState(0);
  const bankList = useSelector((state) => state.bank);
  const dispatch = useDispatch();

  const handleCloseReportType = () =>
    setBankOpening(JSON.parse(JSON.stringify(initialBankOpeningState)));

  const { columns, rows } = BankOpeningData({
    bankOpeningSubmittedFormLoading: bankList.bankOpeningLoading,
    bankOpeningSubmittedForm: bankList.bankOpeningList,
  });

  const handleFilter = async () => {
    setLoading(Constants.PENDING);
    setTablePagination({ ...tablePagination, page: 0 });
    setNext(0);
    const paramData = {
      page: 0,
      perPage: tablePagination.perPage,
    };
    const data = new URLSearchParams(paramData);
    const res = await dispatch(getAllBanksOpeningList(data));
    const firstNameList = [];
    const lastNameList = [];
    const mobileNumberList = [];

    if (res.payload.status === 200) {
      res.payload.data.data.forEach((item) => {
        const { firstName, lastName, mobileNumber } = item;
        if (firstName && firstName !== "") {
          firstNameList.push({ [Constants.MONGOOSE_ID]: firstName, title: firstName });
        }
        if (lastName && lastName !== "") {
          lastNameList.push({ [Constants.MONGOOSE_ID]: lastName, title: lastName });
        }

        if (mobileNumber && mobileNumber !== "") {
          mobileNumberList.push({ [Constants.MONGOOSE_ID]: mobileNumber, title: mobileNumber });
        }
      });
      setLoading(Constants.FULFILLED);
    } else {
      dispatch(
        openSnackbar({
          message: Constants.MOBILE_USERS_ERROR,
          notificationType: Constants.NOTIFICATION_ERROR,
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      handleFilter();
    })();
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const temp = { ...bankOpening };
    temp.body[name] = value;
    setBankOpening(temp);
  };

  const bankFormValidations = () => {
    const {
      beneficiary_name: beneficiaryName,
      beneficiary_email: beneficiaryEmail,
      beneficiary_mobile_no: beneficiaryMobileNo,
      beneficiary_pan: beneficiaryPan,
      beneficiary_adhar: beneficiaryAdhar,
      bank_opening_category: bankOpeningCategory,
      bank_opening_name: bankOpeningName,
    } = bankOpening.body;
    const errors = {};

    const beneficiaryNameValidation = Validator.validate("basic2", beneficiaryName);
    const beneficiaryEmailValidation = Validator.validate("basic", beneficiaryEmail);
    const beneficiaryMobileNoValidation = Validator.validate("basic2", beneficiaryMobileNo);
    const beneficiarypanValidation = Validator.validate("basic2", beneficiaryPan);
    const beneficiaryadharValidation = Validator.validate("basic2", beneficiaryAdhar);
    const bankopeningcategoryValidation = Validator.validate("basic", bankOpeningCategory);
    const bankopeningNameValidation = Validator.validate("basic2", bankOpeningName);

    if (beneficiaryNameValidation !== "") errors.beneficiary_name = beneficiaryNameValidation;
    if (beneficiaryEmailValidation !== "") errors.beneficiary_email = beneficiaryEmailValidation;
    if (beneficiaryMobileNoValidation !== "")
      errors.beneficiary_mobile_no = beneficiaryMobileNoValidation;
    if (beneficiarypanValidation !== "") errors.beneficiary_pan = beneficiarypanValidation;
    if (beneficiaryadharValidation !== "") errors.beneficiary_adhar = beneficiaryadharValidation;
    if (bankopeningcategoryValidation !== "")
      errors.bank_opening_category = bankopeningcategoryValidation;
    if (bankopeningNameValidation !== "") errors.bank_opening_name = bankopeningNameValidation;

    return Object.keys(errors).length > 0 ? errors : {};
  };

  const handleSubmit = async () => {
    const errors = bankFormValidations();
    setBankOpening((prev) => ({ ...prev, errors, loading: true }));
    if (Object.keys(errors).length === 0) {
      const res = await dispatch(submitBankOpeningForm(bankOpening.body));
      if (res.payload.status === 200) {
        handleFilter();
        dispatch(
          openSnackbar({
            message: Constants.SETUP_REPORT_CREATE_SUCCESS,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
        handleCloseReportType();
      } else if (res.payload.status === 422) {
        setBankOpening((prev) => ({
          ...prev,
          errors: { title: Constants.SETUP_REPORT_CREATE_ERROR },
        }));
      }
    } else {
      setBankOpening((prev) => ({ ...prev, errors }));
    }
    setBankOpening((prev) => ({ ...prev, loading: false }));
  };

  const handleTablePagination = async () => {
    const paramData = {
      page: next + 1,
      perPage: tablePagination.perPage,
    };
    const res = await dispatch(
      getAllBanksOpeningList({ param: paramCreater(paramData), byPassSlice: false })
    );
    if (res.payload.status === 200) setNext(res.payload.data.data.length > 0 ? next + 1 : next);
  };

  const handleReload = async () => {
    await dispatch(relaodBankOpeningList());
    handleFilter();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <PageTitle title={PageTitles.BANK_ACCOUNT_OPENING} />
        <MDBox display="flex" flexDirection="row">
          <CustomButton
            title={ButtonTitles.BANK_OPENING}
            icon={Icons.NEW}
            background={Colors.PRIMARY}
            color={Colors.WHITE}
            openModal={() => setBankOpening((prev) => ({ ...prev, openModal: true }))}
          />
          <BasicButton
            icon={Icons.RELOAD}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleReload}
          />
        </MDBox>
      </MDBox>
      <Divider sx={{ marginTop: pxToRem(22) }} />
      {/* <Feature name={FeatureTags.BANK_OPENING}> */}

      <MDBox mt={3} mb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={{ defaultValue: defaultData.PER_PAGE }}
                showTotalEntries={false}
                noEndBorder
                loading={bankList.bankOpeningLoading}
                currentPage={tablePagination.page}
                handleTablePagination={handleTablePagination}
                handleCurrentPage={(page) => setTablePagination({ ...tablePagination, page })}
              />
            </MDBox>
          </Card>
        </Grid>
      </MDBox>

      {/* Create and Update Report type modal */}
      <BasicModal
        open={bankOpening.openModal}
        handleClose={handleCloseReportType}
        title={
          bankOpening.type === "new"
            ? ModalContent.NEW_REPORT_TITLE
            : ModalContent.EDIT_REPORT_TITLE
        }
        actionButton={
          (!bankOpening.loading && ButtonTitles.SUBMIT) ||
          (bankOpening.loading && ButtonTitles.SUBMIT_LOADING)
        }
        disabled={loading}
        handleAction={handleSubmit}
      >
        <FTextField
          label="Full Name*"
          name="beneficiary_name"
          id="beneficiary_name"
          placeholder="Enter Full Name"
          type="text"
          value={bankOpening.body.beneficiary_name}
          handleChange={handleChange}
          width="100%"
          error={bankOpening.errors?.beneficiary_name}
          helperText={bankOpening.errors?.beneficiary_name}
          marginBottom={pxToRem(10)}
        />

        <FTextField
          label="Email*"
          name="beneficiary_email"
          id="beneficiary_email"
          placeholder="Enter Email"
          type="email"
          value={bankOpening.body.beneficiary_email}
          handleChange={handleChange}
          width="100%"
          error={bankOpening.errors?.beneficiary_email}
          helperText={bankOpening.errors?.beneficiary_email}
          marginBottom={pxToRem(10)}
        />

        <FTextField
          label="Mobile Number*"
          name="beneficiary_mobile_no"
          id="beneficiary_mobile_no"
          placeholder="Enter Mobile "
          type="number"
          value={bankOpening.body.beneficiary_mobile_no}
          handleChange={handleChange}
          width="100%"
          error={bankOpening.errors?.beneficiary_mobile_no}
          helperText={bankOpening.errors?.beneficiary_mobile_no}
          marginBottom={pxToRem(10)}
        />
        <FTextField
          label="Pan*"
          name="beneficiary_pan"
          id="beneficiary_pan"
          placeholder="Enter pan number"
          type="text"
          value={bankOpening.body.beneficiary_pan}
          handleChange={handleChange}
          width="100%"
          error={bankOpening.errors?.beneficiary_pan}
          helperText={bankOpening.errors?.beneficiary_pan}
          marginBottom={pxToRem(10)}
        />
        <FTextField
          label="Aadhar*"
          name="beneficiary_adhar"
          id="beneficiary_adhar"
          placeholder="Enter Aadhar number"
          type="text"
          value={bankOpening.body.beneficiary_adhar}
          handleChange={handleChange}
          width="100%"
          error={bankOpening.errors?.beneficiary_adhar}
          helperText={bankOpening.errors?.beneficiary_adhar}
          marginBottom={pxToRem(10)}
        />

        <FDropdown
          label="Bank Category*"
          id="bank_opening_category"
          name="bank_opening_category"
          value={bankOpening.body.bank_opening_category}
          menu={[Constants.SAVING, Constants.CREDIT]}
          error={bankOpening.errors?.bank_opening_category}
          helperText={bankOpening.errors?.bank_opening_category}
          handleChange={(name, value, id) => handleChange({ target: { name, value, id } })}
          marginBottom={0}
          mr={0}
        />

        <FDropdown
          label="Bank*"
          id="bank_opening_name"
          name="bank_opening_name"
          value={bankOpening.body.bank_opening_name}
          menu={[Constants.KOTAK, Constants.AU, Constants.INDUSIND]}
          error={bankOpening.errors?.bank_opening_name}
          helperText={bankOpening.errors?.bank_opening_name}
          handleChange={(name, value, id) => handleChange({ target: { name, value, id } })}
          marginBottom={0}
          mr={0}
        />

        {bankOpening.loading && (
          <MDBox py={5} display="flex" justifyContent="center" alignItems="center">
            {Icons.LOADING2}
          </MDBox>
        )}
      </BasicModal>
      {/* </Feature> */}
    </DashboardLayout>
  );
}

export default SetupReport;
