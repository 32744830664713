import React, { useEffect, useRef, useState } from "react";
import MDBox from "components/MDBox";

// Custom Components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PageTitle from "examples/NewDesign/PageTitle";
import SafetyCardForm from "examples/modal/SafetyCardForm/SafetyCardForm";
import BasicButton from "examples/NewDesign/CustomButton/BasicButton";
import CustomButton from "examples/NewDesign/CustomButton";
import LeadBayListing from "layouts/loanForm/loanData";

// Constant
import Constants, { PageTitles, Colors, Icons } from "utils/Constants";

// Redux component
// import { useNavigate } from "react-router-dom";
import loanConfigThunk, { uploadInventoryFromExcel } from "redux/Thunks/LoanFormConfig";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "redux/Slice/Notification";

// Logos

function ProfileForm() {
  const [open, setOpen] = useState({
    open: false,
    screenIndex: 0,
    cardType: "Incident",
    bgColor: "#043b9f",
  });

  const loanSlice = useSelector((state) => state.loan);

  const inputFileRef = useRef(null);
  const dispatch = useDispatch();

  const handleLoanForm = (formIndex) => {
    setOpen({
      open: true,
      screenIndex: formIndex,
      cardType: "Incident",
      bgColor: "#043b9f",
    });
  };

  const handleReset = () => {
    setOpen({
      open: false,
      screenIndex: 0,
      cardType: "Incident",
      bgColor: "#043b9f",
    });
  };

  useEffect(() => {
    (async () => {
      const res = await dispatch(loanConfigThunk());
      if (res.payload.status !== 200) {
        dispatch(
          openSnackbar({
            message: Constants.MOBILE_USERS_ERROR,
            notificationType: Constants.NOTIFICATION_ERROR,
          })
        );
      }
    })();
  }, []);

  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click(); // Trigger file input click
    }
  };

  const onSubmitHandle = async (e) => {
    try {
      const file = e.target.files[0];

      const response = await dispatch(uploadInventoryFromExcel(file));
      if (response.payload.status === 201) {
        // Assuming successful upload returns status code 201
        dispatch(
          openSnackbar({
            message: Constants.UPLOAD_SUCCESS_MESSAGE,
            notificationType: Constants.NOTIFICATION_SUCCESS,
          })
        );
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
    inputFileRef.current.value = ""; // Reset the file input
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between">
        <PageTitle title={PageTitles.LEAD_BAY} />
        <MDBox display="flex" flexDirection="row">
          {loanSlice.screens.map((screen, index) => (
            <CustomButton
              title={screen.name
                .split(" ")
                .map((word) => word[0])
                .join("")}
              icon={Icons.NEW}
              background={Colors.PRIMARY}
              color={Colors.WHITE}
              openModal={() => handleLoanForm(index)}
            />
          ))}
          <BasicButton
            title="Upload File"
            icon={Icons.PUBLISH}
            background={Colors.WHITE}
            border
            color={Colors.BLACK}
            action={handleButtonClick}
          />
          <input
            type="file"
            name="excelFile"
            accept=".xlsx, .xls"
            onChange={onSubmitHandle}
            ref={inputFileRef}
            style={{ display: "none" }} // Initially hide the file input
            required
            fullWidth
          />
        </MDBox>
      </MDBox>

      {/* Lead bay listing */}
      <LeadBayListing />
      {open.open && (
        <SafetyCardForm
          screenIndex={open.screenIndex}
          cardType={open.cardType}
          openSafetyModal={open.open}
          setOpenSafetyModal={setOpen}
          bgColor={open.bgColor}
          handleReset={handleReset}
        />
      )}
    </DashboardLayout>
  );
}

export default ProfileForm;
